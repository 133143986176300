var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"aHXIhpZ0Dw_-bBNRSpgwj"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { BrowserTracing } from '@sentry/browser';
import * as Sentry from '@sentry/nextjs';
import { Replay as SentryReply } from '@sentry/replay';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION;
const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'dev';

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    // reducing sampling rate in production
    tracesSampleRate: SENTRY_ENVIRONMENT == 'prod' ? 0.1 : 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: SENTRY_ENVIRONMENT == 'prod' ? 0.1 : 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      new BrowserTracing({
        tracingOrigins: [
          process.env.NEXT_PUBLIC_API_BASE_URL ?? '',
          process.env.NEXT_PUBLIC_GRAPHQL_URL ?? '',
        ],
      }),
      new SentryReply(),
    ],
    release: APP_VERSION,
    environment: SENTRY_ENVIRONMENT,
  });
}

export {};
